"use client";

import React, { useEffect, useState } from "react";
import styles from "./Nav.module.scss";
import Link from "next/link";
import Logo from "@/components/icons/Logo";
import { usePathname } from "next/navigation";
import useNavRetract from "@/hooks/useNavRetract";
import If from "@/components/common/If";
import { Details } from "../Details";
import { Transition } from "@/components/common/Transition";

import { useNav } from "@/providers/Nav";
import { NavGuts } from "../NavGuts";

const NavLink = ({ link = {} }) =>
  link?.href?.includes("malto:") ? (
    <li className="stack gap-2">
      <a
        href={link.href}
        className="w-full flex justify-between items-center pr-3"
        target="_blank"
      >
        <h2
          className="text-[1.25rem] font-hero text-current"
          dangerouslySetInnerHTML={{ __html: link.title }}
        />
        <span className="">
          <svg
            width="22"
            viewBox="0 0 27 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1.25"
              y1="0.566987"
              x2="26.5116"
              y2="15.1518"
              stroke="#EAE8E4"
            />
            <line
              x1="0.832031"
              y1="29.567"
              x2="26.0936"
              y2="14.9822"
              stroke="#EAE8E4"
            />
          </svg>
        </span>
      </a>
      <If condition={link.description}>
        <span className="text-xs font-thin text-white text-left">
          {link.description}
        </span>
      </If>
    </li>
  ) : (
    <li className="stack gap-2 items-start">
      <Link
        href={link.href}
        className="w-full flex justify-between items-center pr-3"
      >
        <h2
          className="text-[1.25rem] font-hero text-current"
          dangerouslySetInnerHTML={{ __html: link.title }}
        />
        <span className="">
          <svg
            width="22"
            viewBox="0 0 27 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1.25"
              y1="0.566987"
              x2="26.5116"
              y2="15.1518"
              stroke="#EAE8E4"
            />
            <line
              x1="0.832031"
              y1="29.567"
              x2="26.0936"
              y2="14.9822"
              stroke="#EAE8E4"
            />
          </svg>
        </span>
      </Link>
      <If condition={link.description}>
        <span className="text-xs font-thin text-white text-left">
          {link.description}
        </span>
      </If>
    </li>
  );
/**
 *
 * Nav
 *
 */
const Nav = ({ ...props }) => {
  const pathname = usePathname();
  const { setNavRetracted, navRetracted } = useNav();
  const { isHeaderVisible, isLowerThanNav } = useNavRetract({ pathname });
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openTab, setOpenTab] = useState(-1);

  useEffect(() => {
    console.log("pathname", pathname);
    setOpenTab(-1);
    setMobileMenuOpen(false);
  }, [pathname]);

  useEffect(() => {
    setNavRetracted(!isHeaderVisible);
  }, [isHeaderVisible]);

  const getBackground = () => {
    if (
      pathname?.includes("/technical-resources") ||
      pathname?.includes("/news") ||
      pathname === "/" ||
      pathname?.includes("/customer-service-contacts") ||
      pathname?.includes("/find-a-rep") ||
      pathname?.includes("/blog")
    ) {
      return "bg-nav";
    }

    if (isLowerThanNav || openTab > -1) {
      return "bg-black";
    }

    return "bg-[transparent] border-b border-b-[#9B9B9B]";
  };

  return (
    <>
      <header
        className={`main-nav fixed top-0 left-0 right-0 z-[15002] transition-all duration-300 w-[100dvw] ease max-w-full px-4 md:px-gutter py-4 ${
          isLowerThanNav && !isHeaderVisible ? "retract" : ""
        } ${openTab > -1 ? "bg-black border-b border-b-[#9B9B9B]" : getBackground()}`}
      >
        <div className="flex justify-between items-center">
          <Link href="/">
            <Logo className="w-[125px] md:w-[239px]" />
          </Link>
          <button
            onClick={() => {
              setMobileMenuOpen(!mobileMenuOpen);
            }}
            className="flex relative z-[10000] md:hidden pulse bg-gold w-[35px] h-[35px] items-center justify-center rounded-full aspect-[1/1]"
          >
            <span className="sr-only">Menu</span>
          </button>
          <div className="hidden lg:flex items-center gap-gutter text-nav-text nav-links">
            <button
              onClick={() => setOpenTab(0)}
              className={`${openTab === 0 ? "active" : ""}`}
            >
              Products
            </button>
            <button
              onClick={() => setOpenTab(1)}
              className={`${openTab === 1 ? "active" : ""}`}
            >
              Markets
            </button>
            <button
              onClick={() => setOpenTab(2)}
              className={`${openTab === 2 ? "active" : ""}`}
            >
              Solutions
            </button>
            <button
              onClick={() => setOpenTab(3)}
              className={`${openTab === 3 ? "active" : ""}`}
            >
              Resources
            </button>
            <Link href="/about" className={`${openTab === 4 ? "active" : ""}`}>
              About
            </Link>
            <button
              onClick={() => setOpenTab(5)}
              className={`${openTab === 5 ? "active" : ""}`}
            >
              Contact
            </button>
          </div>
        </div>
      </header>

      <If condition={mobileMenuOpen}>
        <div
          className="fixed top-0 left-0 w-full h-full bg-maroon overflow-y-auto z-[15001] px-4 pt-35 pb-25"
          data-lenis-prevent
        >
          <NavGuts />
        </div>
      </If>

      <If condition={openTab > -1}>
        <Transition
          isVisible={openTab > -1}
          enterAnimation="fadeIn"
          className="fixed top-0 left-0 w-full h-full blur z-[15001]"
          duration={300}
          // onClick={(ev) => {
          //   ev.stopPropagation();
          //   setOpenTab(-1);
          // }}
        >
          <></>
        </Transition>
        <Transition
          isVisible={openTab > -1}
          enterAnimation="slideIn"
          as="nav"
          className="nav fixed top-0 right-0 z-[15001] transition-all duration-300 w-[660px] ease max-w-full max-h-[100dvh] overflow-y-auto bg-maroon px-gutter pt-35 pb-25 flex flex-col"
          data-lenis-prevent
          key={openTab}
        >
          <button
            onClick={() => setOpenTab(-1)}
            className="absolute top-24 right-gutter z-[15002] transition-all duration-300"
          >
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="V"
                y1="0.943322"
                x2="32.0564"
                y2="31.3489"
                stroke="#B7974A"
              />
              <line
                x1="0.943688"
                y1="31.3496"
                x2="31.3493"
                y2="0.943981"
                stroke="#B7974A"
              />
            </svg>
          </button>

          <If condition={openTab === 0}>
            <Details
              items={[
                {
                  title: "Doors By Series",
                  description: (
                    <>
                      <ul className="stack gap-4">
                        {[
                          {
                            title: "Aspiro<sup>®</sup> Series",
                            href: "/series/aspiro-series",
                          },
                          {
                            title: "Cendura<sup>®</sup> Series",
                            href: "/series/cendura-series",
                          },
                        ].map((link) => (
                          <NavLink link={link} />
                        ))}
                      </ul>
                    </>
                  ),
                },
                {
                  title: "Doors By Category",
                  description: (
                    <>
                      <ul className="stack gap-4">
                        {[
                          {
                            title: "Flush Wood Veneer",
                            href: "/categories/flush-wood-veneer",
                          },
                          {
                            title: "Flush Paintable",
                            href: "/categories/flush-paintable",
                          },
                          {
                            title: "Flush Laminates",
                            href: "/categories/flush-laminates",
                          },
                          {
                            title: "Stile & Rail",
                            href: "/categories/stile-and-rail-landing",
                          },
                        ].map((link) => (
                          <NavLink link={link} />
                        ))}
                      </ul>
                    </>
                  ),
                },
                {
                  title: "Doors By Market",
                  description: (
                    <>
                      <ul className="stack gap-4">
                        {[
                          {
                            title: "Healthcare",
                            href: "/markets/healthcare",
                          },
                          {
                            title: "Hosptitality",
                            href: "/markets/hospitality",
                          },
                          {
                            title: "Education",
                            href: "/markets/education",
                          },
                          {
                            title: "Office / Mixed-Use",
                            href: "/markets/office",
                          },
                        ].map((link) => (
                          <NavLink link={link} />
                        ))}
                      </ul>
                    </>
                  ),
                },
                {
                  title: "Doors By Solution",
                  description: (
                    <>
                      <ul className="stack gap-4">
                        {[
                          {
                            title: "Acoustics",
                            href: "/solutions/acoustics",
                          },
                          {
                            title: "Aesthetics",
                            href: "/solutions/aesthetics",
                          },
                          {
                            title: "Cleanability",
                            href: "/solutions/cleanability",
                          },
                          {
                            title: "Durability",
                            href: "/solutions/durability",
                          },
                          // {
                          //   title: "Safety",
                          //   href: "/solutions/safety",
                          // },
                          // {
                          //   title: "Security",
                          //   href: "/solutions/security",
                          // },
                        ].map((link) => (
                          <NavLink link={link} />
                        ))}
                      </ul>
                    </>
                  ),
                },
                {
                  title: "Baillargeon",
                  link: "https://baillargeondoors.com",
                  external: true,
                  icon: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      viewBox="0 0 9600 4800"
                    >
                      <title>Flag of Canada</title>
                      <path
                        fill="#f00"
                        d="m0 0h2400l99 99h4602l99-99h2400v4800h-2400l-99-99h-4602l-99 99H0z"
                      />
                      <path
                        fill="#fff"
                        d="m2400 0h4800v4800h-4800zm2490 4430-45-863a95 95 0 0 1 111-98l859 151-116-320a65 65 0 0 1 20-73l941-762-212-99a65 65 0 0 1-34-79l186-572-542 115a65 65 0 0 1-73-38l-105-247-423 454a65 65 0 0 1-111-57l204-1052-327 189a65 65 0 0 1-91-27l-332-652-332 652a65 65 0 0 1-91 27l-327-189 204 1052a65 65 0 0 1-111 57l-423-454-105 247a65 65 0 0 1-73 38l-542-115 186 572a65 65 0 0 1-34 79l-212 99 941 762a65 65 0 0 1 20 73l-116 320 859-151a95 95 0 0 1 111 98l-45 863z"
                      />
                    </svg>
                  ),
                },
                {
                  title: "Services",
                  description: (
                    <>
                      <ul className="stack gap-4">
                        {[
                          {
                            title: "USA Wood Door",
                            href: "https://usawooddoor.com/",
                            external: true,
                          },
                        ].map((link) => (
                          <NavLink link={link} />
                        ))}
                      </ul>
                    </>
                  ),
                },
                // { title: "Components" },
              ]}
            />
          </If>
          <If condition={openTab === 1}>
            <Details
              items={[
                {
                  title: "Healthcare",
                  link: "/markets/healthcare",
                },
                {
                  title: "Hospitality",
                  link: "/markets/hospitality",
                },
                {
                  title: "Education",
                  link: "/markets/education",
                },
                {
                  title: "Office / Mixed-Use",
                  link: "/markets/office",
                },
              ]}
            />
          </If>
          <If condition={openTab === 2}>
            <Details
              items={[
                {
                  title: "Acoustics",
                  link: "/solutions/acoustics",
                },
                {
                  title: "Aesthetics",
                  link: "/solutions/aesthetics",
                },
                {
                  title: "Cleanability",
                  link: "/solutions/cleanability",
                },
                {
                  title: "Durability",
                  link: "/solutions/durability",
                },
                {
                  title: "Safety/Security",
                  link: "/categories/aspiro-defendr-door-system",
                },
                // {
                //   title: "Security",
                //   link: "/solutions/security",
                // },
              ]}
            />
          </If>
          <If condition={openTab === 3}>
            <Details
              items={[
                {
                  title: "Samples",
                  description: (
                    <>
                      <ul className="stack gap-4">
                        {[
                          {
                            title: "Order Samples",
                            href: "/samples",
                          },
                        ].map((link) => (
                          <NavLink link={link} />
                        ))}
                      </ul>
                    </>
                  ),
                },
                {
                  title: "Quote & Order Forms",
                  link: "/technical-resources?tags=quoteOrderForms",
                },
                // {
                //   title: "Quote & Order Forms",
                //   description: (
                //     <>
                //       <ul className="stack gap-4">
                //         {[
                //           {
                //             title: "Forms & Calculators",
                //             href: "/technical-resource/forms-and-calculators",
                //           },
                //           {
                //             title: "Handling Charts",
                //             href: "/technical-resource/handling-charts",
                //           },
                //           {
                //             title: "Machining Sheets",
                //             href: "/technical-resource/machining-sheets",
                //           },
                //         ].map((link) => (
                //           <NavLink link={link} />
                //         ))}
                //       </ul>
                //     </>
                //   ),
                // },
                {
                  title: "Specification Documents",
                  link: "/technical-resources?tags=specificationDocuments",
                  // description: (
                  //   <>
                  //     <ul className="stack gap-4">
                  //       {[
                  //         {
                  //           title: "Specification Documents",

                  //         },
                  //         // {
                  //         //   title: "3-Part Specifications",
                  //         //   href: "/technical-resource/3-part-specifications",
                  //         // },
                  //       ].map((link) => (
                  //         <NavLink link={link} />
                  //       ))}
                  //     </ul>
                  //   </>
                  // ),
                },
                {
                  title: "Technical Documents",
                  description: (
                    <>
                      <ul className="stack gap-4">
                        {[
                          {
                            title: "Appendices",
                            href: "/technical-resources?tags=appendices",
                          },
                          {
                            title: "Product Data Sheets (PDS)",
                            href: "/technical-resources?tags=productDataSheetsPds",
                          },
                          {
                            title: "Tech Updates",
                            href: "/technical-resources?tags=techUpdates",
                          },
                          {
                            title: "Care & Handling",
                            href: "/technical-resources?tags=careHandling",
                          },
                          {
                            title: "Drawings & Renderings",
                            href: "/technical-resources?tags=drawingsRenderings",
                          },
                          {
                            title: "Warranty",
                            href: "/technical-resources?tags=warranty",
                          },
                        ].map((link) => (
                          <NavLink link={link} />
                        ))}
                      </ul>
                    </>
                  ),
                },
                {
                  title: "Training",
                  description: (
                    <>
                      <ul className="stack gap-4">
                        {[
                          {
                            title: "DoorUniversity",
                            href: "/dooruniversity",
                          },
                          {
                            title: "Continuing Education Units (CEUs)",
                            href: "/continuing-education-units",
                          },
                          {
                            title: "DoorBuilder",
                            href: "/doorbuilder",
                          },
                          // {
                          //   title: "TechTalks",
                          //   href: "/techtalks",
                          // },
                        ].map((link) => (
                          <NavLink link={link} />
                        ))}
                      </ul>
                    </>
                  ),
                },
                {
                  title: "Education",
                  description: (
                    <>
                      <ul className="stack gap-4">
                        {[
                          // {
                          //   title: "Acoustics eBook",
                          //   href: "#",
                          // },
                          {
                            title: "FAQs Veneer Cuts",
                            href: "/veneer-faqs",
                          },
                          {
                            title: "Green Standards Glossary",
                            href: "https://cdn.bfldr.com/QL33D4U/as/9rrcx8mh443zm9kxgx9cntqm/2024-FOS-TUD-101-1001-Tech-Update-101-GreenStandards",
                          },
                          {
                            title: "WDMA QSI",
                            href: "https://cdn.bfldr.com/QL33D4U/as/vshhpjn8wngcx8g2sv8h7r3/wdma_tech_bulletin-qsi-wdma-",
                          },
                          // {
                          //   title: "Blog",
                          //   href: "/blog",
                          // },
                        ].map((link) => (
                          <NavLink link={link} />
                        ))}
                      </ul>
                    </>
                  ),
                },
                {
                  title: "Case Studies",
                  link: "/case-studies",
                },
                {
                  title: "All Resources",
                  link: "/technical-resources",
                },
              ]}
            />
          </If>
          <If condition={openTab === 5}>
            <Details
              items={[
                // {
                //   title: "Warranty",
                //   link: "/technical-resources/warranty",
                //   detail:
                //     "Activate your warranty or learn more about product handling, care and customer warranty requests.",
                // },
                // {
                //   title: "Training and Coutinuing Education",
                //   link: "/training",
                //   detail:
                //     "Everything you need to become an expert! From overall product training to mastering and navigating systems.",
                // },
                // {
                //   title: "Quote & Order Support",
                //   link: "/contact-us/customer-service-contacts",
                //   detail:
                //     "Distributors, please contact us for estimates, orders, quote requests, general or technical questions and more!.",
                // },
                // {
                //   title: "Project and Specification Support",
                //   link: "mailto:designmanager@masonite.com",
                //   detail:
                //     "Project support and specification assistants for architects, owners and general contractors.",
                // },
                // {
                //   title: "Find a Territory Rep",
                //   link: "/contact-us/find-a-rep",
                //   detail: "Find a Territory Rep for your state.",
                // },
                {
                  title: "Contact Us",
                  description: (
                    <>
                      <ul className="stack gap-4">
                        {[
                          {
                            title: "Warranty",
                            href: "/technical-resources/warranty",
                            description:
                              "Activate your warranty or learn more about product handling, care and customer warranty requests.",
                          },
                          {
                            title: "Training and Continuing Education",
                            href: "/training",
                            description:
                              "Everything you need to become an expert! From overall product training to mastering and navigating systems.",
                          },
                          {
                            title: "Quote & Order Support",
                            href: "/contact-us/customer-service-contacts",
                            description:
                              "Distributors, please contact us for estimates, orders, quote requests, general or technical questions and more!",
                          },
                          {
                            title: "Project and Specification Support",
                            href: "mailto:designmanager@masonite.com",
                            description:
                              "Project support and specification assistants for architects, owners and general contractors.",
                          },
                          {
                            title: "Find a Territory Rep",
                            href: "/contact-us/find-a-rep",
                            description: "Find a Territory Rep for your state.",
                          },
                        ].map((link) => (
                          <NavLink link={link} />
                        ))}
                      </ul>
                    </>
                  ),
                },
                { title: "Careers", link: "/careers" },
              ]}
            />
          </If>
        </Transition>
      </If>
    </>
  );
};

export default Nav;
